/**
 * map/tree.js is a module to wrap the RBush tree spatial index generated by
 * the epsg-registry-flattener project.
 */
import axios from 'axios'
import RBush from 'rbush'

let tree = null;

function loadTree() {
    return axios.get(
        '/data/out/epsg_areas_rbush_tree.json'
    ).then((response) => {
        if (response.data
                && response.data.children
                && response.data.children.length > 0) {
            tree = new RBush(9)
            tree.fromJSON(response.data)
            return tree
        }
        return false
    }).catch(() => {})
}

function getTree() {
    return new Promise((resolve) => {
        if (tree) resolve(tree)
        else return loadTree()
    })
}

export {
    loadTree,
    getTree
}